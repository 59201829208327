.App {
  min-height: 100vh;
  padding: 2rem;
}

h1 {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.full-width {
  grid-column: span 2;
}

h2 {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.dashboard-card {
  padding: 1.5rem;
}

@media screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  .full-width {
    grid-column: span 1;
  }
}