.account-metrics-table {
    width: 100%;
    overflow-x: auto;
  }
  
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .custom-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .custom-table th,
  .custom-table td {
    padding: 0.75rem;
    text-align: left;
  }
  
  .custom-table th {
    font-weight: 600;
  }
  
  @media screen and (max-width: 768px) {
    .custom-table {
      display: block;
    }
  
    .custom-table thead {
      display: none;
    }
  
    .custom-table tbody,
    .custom-table tr,
    .custom-table td {
      display: block;
      width: 100%;
    }
  
    .custom-table tr {
      margin-bottom: 1rem;
      border: 1px solid #ccc;
    }
  
    .custom-table td {
      text-align: right;
      padding-left: 50%;
      position: relative;
    }
  
    .custom-table td::before {
      content: attr(data-label);
      position: absolute;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      text-align: left;
      font-weight: bold;
    }
  }